<!-- modal-content.component.html -->
<div class="my-custom-modal">
    <div class="header-modal">
        <p *ngIf="dataModal.auditing || dataModal.auditingSocial">Cadastro de empresa auditora</p>
        <p *ngIf="!dataModal.auditing && !dataModal.laboratory && !dataModal.auditingSocial">{{ dataModal.filial ?
            'Cadastro de Filial' :
            'Cadastro de fornecedores terceiros'
            }}</p>
        <p *ngIf="dataModal.laboratory">Cadastro de laboratório</p>
    </div>

    <div class="body-modal">
        <form [formGroup]="form" class="body-modal">

            <div class="row-input" style="display: flex;">
                <div class="form-group" id="input-provider" tabindex="-1">
                    <input style="width:350px;" type="text" class="input-search form-input" id="providerName"
                        name="provider" formControlName="providerName" />

                    <label *ngIf="dataModal?.auditing || dataModal?.auditingSocial" class="form-label" for="provider"
                        [ngClass]="{ focus: form.get('providerName')?.value  }">
                        Empresa auditora
                    </label>

                    <label *ngIf="dataModal?.laboratory" class="form-label" for="provider"
                        [ngClass]="{ focus: form.get('providerName')?.value  }">
                        Laboratório
                    </label>

                    <label *ngIf="!dataModal.auditing && !dataModal?.laboratory && !dataModal?.auditingSocial"
                        class="form-label" for="provider" [ngClass]="{ focus: form.get('providerName')?.value  }">
                        {{ dataModal.filial ? 'Nome da filial' : 'Nome do fornecedor terceiro' }}
                    </label>

                    <span class="alert-input" *ngIf="
                    form.get('providerName')?.errors &&
                    (form.get('providerName')?.touched ||
                    form.get('providerName')?.dirty)
                ">Campo obrigatório</span>

                    <!--
                    <div *ngIf="openOptions && form.get('provider')?.value" class=" container-options"
                        style="margin-top:-8px;height:150px;z-index: 9;">
                        <div class="option" *ngFor="let company of auditingCompanyList" (click)="getValue(company)">
                            <p>{{company.corporateName}}</p>
                        </div>
                    </div>
                    -->
                </div>


                <div class="form-group" tabindex="-1">
                    <input style="width:350px;" type="text" formControlName="cnpj"
                        mask="000.000.000-00||00.000.000/0000-00" class="form-input" />

                    <label *ngIf="!dataModal?.laboratory && !dataModal?.auditingSocial"
                        [ngClass]="{ focus: form.get('cnpj')?.value  }" class="form-label">
                        {{ dataModal?.filial ? 'Documento da filial' : 'Documento do fornecedor' }}
                    </label>

                    <label *ngIf="dataModal?.laboratory || dataModal?.auditingSocial"
                        [ngClass]="{ focus: form.get('cnpj')?.value  }" class="form-label">
                        Documento da empresa
                    </label>

                    <span class="alert-input" *ngIf="
                    form.get('cnpj')?.errors &&
                    (form.get('cnpj')?.touched ||
                    form.get('cnpj')?.dirty)
                ">Campo obrigatório</span>
                </div>
            </div>

            <div class="row-input" style="display: flex;">
                <div class="form-group" tabindex="-1">
                    <input style="width:350px;" type="text" formControlName="postalCode" class="form-input"
                        mask="00.000-000" (change)="onPostalCodeChange()" />

                    <label
                        [ngClass]="{ focus : (form.get('postalCode')?.value && form.get('postalCode')?.value.length > 0) }"
                        class="form-label">
                        CEP
                    </label>
                </div>

                <div class="form-group" tabindex="-1">
                    <input style="width:350px;" type="text" formControlName="state" class="form-input" />

                    <label [ngClass]="{ focus : (form.get('state')?.value && form.get('state')?.value.length > 0) }"
                        class="form-label">
                        Estado
                    </label>
                </div>


            </div>

            <div class="row-input" style="display: flex;">
                <div class="form-group" tabindex="-1">
                    <input style="width:350px;" type="text" formControlName="city" class="form-input" />

                    <label [ngClass]="{ focus : (form.get('city')?.value && form.get('city')?.value.length > 0) }"
                        class="form-label">
                        Cidade
                    </label>
                </div>

                <div class="form-group" tabindex="-1">
                    <input style="width:350px;" type="text" formControlName="neighborhood" class="form-input" />

                    <label
                        [ngClass]="{ focus : (form.get('neighborhood')?.value && form.get('neighborhood')?.value.length > 0) }"
                        class="form-label">
                        Bairro
                    </label>
                </div>
            </div>

            <div class="row-input" style="display:flex">
                <div class="form-group" tabindex="-1">
                    <input style="width:350px;" type="text" formControlName="street" class="form-input" />

                    <label [ngClass]="{ focus : (form.get('street')?.value && form.get('street')?.value.length > 0) }"
                        class="form-label">
                        Rua
                    </label>
                </div>

                <div class="form-group" tabindex="-1">
                    <input style="width:350px;" type="text" formControlName="number" class="form-input" />

                    <label [ngClass]="{ focus : (form.get('number')?.value && form.get('number')?.value.length > 0) }"
                        class="form-label">
                        Número
                    </label>
                </div>
            </div>

            <div class="row-input" style="display:flex">
                <div class="select-container" tabindex="-1"
                    *ngIf="!dataModal?.auditing && !dataModal?.laboratory && !dataModal?.auditingSocial">
                    <select style="width:383px;" type="text" class="form-input select" id="type" name="type"
                        formControlName="type">
                        <option value="" disabled></option>
                        <option *ngFor="let type of types; let i = index" [value]="type.value"> {{ type.name }}</option>
                    </select>

                    <label class="form-label" for="type"
                        [ngClass]="{ focus: form.get('type')?.value && form.get('type')?.value.length > 0 }">
                        Tipo
                    </label>

                    <span class="alert" *ngIf="
                form.get('type')?.errors &&
                (form.get('type')?.touched ||
                  form.get('type')?.dirty)
              ">Campo obrigatório
                    </span>
                </div>
            </div>
        </form>
    </div>

    <div class="buttons">
        <button class="button-export" id="first-button-export" (click)="openModalConfirm()">
            <span style="font-weight: 700;font-family: 'Roboto';font-style: normal;color:white">Confirmar</span>
        </button>

        <button class="button-clean" (click)="closeModal()">
            <span>Cancelar</span>
        </button>
    </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>