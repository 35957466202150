import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { SelectInputComponent } from '../select-input/select-input.component';
import { IntegracaoService } from 'src/app/services/integracao.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { addTypeOfExportXlsx } from '../../utils/see-integration-utils/integration-utils';
import { dateValidator } from 'src/app/validators/dateValidator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dateNotBeforeValidator } from 'src/app/validators/dataBeforeValidator';
import { dateNotBefore } from 'src/app/validators/dateNotBeforeValidator';

interface Integracao {
  integration: String;
  provider: String;
  documentProvider: String;
  productName: String;
  invoiceNumber: number;
  identifier: number;
  movementDate: String;
  completionDate: String;
  requestedQuantity: number;
  amount: number;
  receivedQuantity: number;
  returnedQuantity: number;
  error: String;
  type: String;
}

@Component({
  selector: 'app-see-integration',
  templateUrl: './see-integration.component.html',
  styleUrls: ['./see-integration.component.scss'],
})
export class SeeIntegrationComponent implements OnInit {
  @ViewChild('inputProvider') inputProvider!: SelectInputComponent;
  protected inputProviderIsTouched: boolean = false;

  protected dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  protected storage!: Storage;
  protected providerList: Array<any> = [];
  protected tableData: Array<any> = [];
  protected list: Array<any> = [];
  protected loading: boolean = false;

  protected isDate: boolean = false;
  protected isDivergence: boolean = false;
  protected isReceipt: boolean = false;

  protected classProduct: string = '';
  protected tableActive: boolean = false;

  protected checkboxActive: {
    provider: boolean;
  } = { provider: false };
  protected providerCompanyList: Array<{
    checked: boolean;
    value: string;
    document: string;
  }> = [];
  protected firstRender: boolean = true;

  protected form = new FormGroup({
    document: new FormControl(''),
    start: new FormControl('', [
      Validators.required,
      dateValidator,
      dateNotBeforeValidator(new Date(2020, 0, 1)),
    ]),
    finish: new FormControl('', [
      Validators.required,
      dateValidator,
      dateNotBefore('startDate'),
    ]),
  });

  protected isUserProvider: boolean = false;
  protected buttonDisabled: boolean = true;
  protected user: any;
  protected provider: any;

  minCompletionDate: any;
  maxCompletionDate: any;

  protected displayedColumns: string[] = [];
  isCarrefour: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  startDate: any;
  finishDate: any;
  status: any;
  length: number = 0;

  constructor(
    private router: Router,
    private integracaoService: IntegracaoService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    const maxDate = new Date();
    this.maxCompletionDate = maxDate.setDate(maxDate.getDate());
    if (this.isCarrefour) {
      this.dataSource.paginator = this.paginator;
    }
    this.storage = window.sessionStorage;
  }

  ngOnInit(): void {
    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};

    if (
      this.user.authorities.some((item: string) => item === 'ROLE_PROVIDER')
    ) {
      this.isUserProvider = true;
    }

    if (
      this.user.authorities.some((item: string) => item.includes('CARREFOUR'))
    ) {
      this.isCarrefour = true;
    }

    let linkClass = this.router.url.split('/');
    let classProduct = localStorage.getItem('classProduct');

    if (classProduct != null) {
      this.classProduct = classProduct;
    }

    if (linkClass[1] == 'minha-empresa') {
      this.isUserProvider = true;
      this.buttonDisabled = false;

      const customerId = this.user.customerId;
      let providerDocument = atob(customerId);

      this.form.get('document')?.setValue(providerDocument);

      if (linkClass[3] == 'recebimento-integracao') {
        this.isReceipt = true;
        this.displayedColumns = [
          'provider',
          'documentProvider',
          'productName',
          'invoiceNumber',
          'identifier',
          'completionDate',
          'requestedQuantity',
          'receivedQuantity',
          'returnedQuantity',
        ];
      } else if (linkClass[3] == 'divergencia-integracao') {
        this.isDivergence = true;
        this.displayedColumns = [
          'integration',
          'provider',
          'documentProvider',
          'productName',
          'invoiceNumber',
          'identifier',
          'movementDate',
          'completionDate',
          'requestedQuantity',
          'amount',
          'receivedQuantity',
          'returnedQuantity',
          'error',
        ];
      } else if (linkClass[3] == 'dados-nao-processados') {
        this.isDate = true;
        this.displayedColumns = [
          'provider',
          'documentProvider',
          'productName',
          'invoiceNumber',
          'amount',
          'error',
        ];
      }

      return;
    }

    if (linkClass[2] == 'recebimento-integracao') {
      this.isReceipt = true;
      this.displayedColumns = [
        'provider',
        'documentProvider',
        'productName',
        'invoiceNumber',
        'identifier',
        'completionDate',
        'requestedQuantity',
        'receivedQuantity',
        'returnedQuantity',
      ];
    } else if (linkClass[2] == 'divergencia-integracao') {
      this.isDivergence = true;
      this.displayedColumns = [
        'integration',
        'provider',
        'documentProvider',
        'productName',
        'invoiceNumber',
        'identifier',
        'movementDate',
        'completionDate',
        'requestedQuantity',
        'amount',
        'receivedQuantity',
        'returnedQuantity',
        'error',
      ];
    } else if (linkClass[2] == 'dados-nao-processados') {
      this.isDate = true;
      this.displayedColumns = [
        'provider',
        'documentProvider',
        'productName',
        'invoiceNumber',
        'amount',
        'error',
      ];
    }
  }

  removeSemicolonAtEnd(str: string): string {
    return str.replace(/;$/, '');
  }

  protected submitForm(): void {
    this.form.markAllAsTouched();
    const startDate: any = this.form.get('start');
    const finishDate: any = this.form.get('finish');
    const document = this.form.get('document')?.value;
    if (!this.form.valid) {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: 'Preencha os filtros',
        },
      });
      return;
    }

    if (this.isCarrefour) {
      this.status = 'NOT INFORMED';
      if (this.isReceipt) {
        this.status = 'NONE';
      } else if (this.isDivergence) {
        this.status = 'PARTIALLY';
      } else if (this.isDate) {
        this.status = 'FAILURE';
      }
      this.startDate = startDate.value;
      this.finishDate = finishDate.value;

      this.listCarrefour();
    } else {
      this.listData();
    }
  }

  listData(pageEvent?: PageEvent) {
    const startDate = this.form.get('start')?.value;
    const finishDate = this.form.get('finish')?.value;
    const document = this.form.get('document')?.value;

    if (pageEvent) {
      this.pageIndex = pageEvent.pageIndex;
      this.pageSize = pageEvent.pageSize;
    } else {
      this.pageIndex = 0;
      this.pageSize = 15;
    }

    if (this.isReceipt) {
      this.listReceiptWithoutIntegration(
        startDate as string,
        finishDate as string,
        document as string
      );
    } else if (this.isDivergence) {
      this.listDivergentData(
        startDate as string,
        finishDate as string,
        document as string
      );
    } else if (this.isDate) {
      this.listUnprocessedData(
        startDate as string,
        finishDate as string,
        document as string
      );
    }
  }

  public listCarrefour(pageEvent?: PageEvent) {
    if (pageEvent) {
      this.pageIndex = pageEvent.pageIndex;
      this.pageSize = pageEvent.pageSize;
    } else {
      this.pageIndex = 0;
      this.pageSize = 15;
    }

    const document = this.form.get('document')?.value;

    const options: any = {
      startDate: this.startDate,
      endDate: this.finishDate,
      status: this.status,
      page: this.pageIndex,
      size: this.pageSize,
      providerDocument: document,
    };

    this.loading = true;
    this.integracaoService.getCarrefourData(options).subscribe({
      next: (res: any) => {
        this.pageIndex = res.currentPage;
        this.length = res.totalItems;

        this.list = res.content;
        this.length = res.totalElements;

        this.tableData = this.list;
        if (this.tableData && this.tableData.length <= 0) {
          this._snackBar.open(
            'Nenhum registro encontrado para o filtro solicitado',
            undefined,
            {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: 'right',
              verticalPosition: 'top',
            }
          );
        }

        this.dataSource = new MatTableDataSource(this.tableData);

        this.getProviderCompanies();

        this.tableActive = true;
        this.loading = false;
      },
      error: (err) => {
        this.list = [];
        this.length = 0;
        this.tableData = this.list;
        this.dataSource = new MatTableDataSource(this.tableData);
        this._snackBar.open(
          'Nenhum registro encontrado para o filtro solicitado',
          undefined,
          {
            duration: 2 * 1000,
            panelClass: ['alert_error'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
          }
        );

        this.loading = false;
      },
    });
  }

  public listReceiptWithoutIntegration(
    startDate: string,
    finishDate: string,
    providerDocument: string
  ) {
    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};

    if (
      this.user.authorities.some((item: string) => item === 'ROLE_PROVIDER')
    ) {
      this.isUserProvider = true;
    } else {
      this.isUserProvider = false;
    }

    if (this.classProduct === 'flv') {
      this.classProduct = 'FLV';
    }

    const options = {
      startDate: startDate,
      finishDate: finishDate,
      providerDocument: this.isUserProvider ? providerDocument : '',
      productType: this.isUserProvider ? '' : this.classProduct,
      size: this.pageSize,
      page: this.pageIndex,
    };

    this.loading = true;

    this.integracaoService.getListReceiptWithoutIntegration(options).subscribe({
      next: (res: any) => {
        this.list = res.content;
        this.tableData = this.list;
        if (this.tableData && this.tableData.length <= 0) {
          this._snackBar.open(
            'Nenhum registro encontrado para o filtro solicitado',
            undefined,
            {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: 'right',
              verticalPosition: 'top',
            }
          );
        }
        this.length = res.totalElements;

        if (this.providerCompanyList.some((e) => e.checked)) {
          this.tableData = this.tableData.filter(
            (elem: any) =>
              this.providerCompanyList.filter(
                (providerCompany) =>
                  elem?.provider &&
                  elem?.provider.toLowerCase() ===
                    providerCompany.value.toLowerCase() &&
                  providerCompany.checked
              ).length > 0
          );
        }
        this.dataSource = new MatTableDataSource(this.tableData);
        this.getProviderCompanies();

        this.tableActive = true;
        this.loading = false;
      },
      error: (err) => {
        this.list = [];
        this.length = 0;
        this.tableData = this.list;
        this.dataSource = new MatTableDataSource(this.tableData);
        this._snackBar.open(
          'Nenhum registro encontrado para o filtro solicitado',
          undefined,
          {
            duration: 2 * 1000,
            panelClass: ['alert_error'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
          }
        );

        this.loading = false;
      },
    });
  }

  public listDivergentData(
    startDate: any,
    finishDate: any,
    providerDocument: any
  ) {
    if (this.classProduct === 'flv') {
      this.classProduct = 'FLV';
    }

    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};

    if (
      this.user.authorities.some((item: string) => item === 'ROLE_PROVIDER')
    ) {
      this.isUserProvider = true;
    } else {
      this.isUserProvider = false;
    }

    const options = {
      startDate: startDate,
      finishDate: finishDate,
      providerDocument: this.isUserProvider ? providerDocument : '',
      productType: this.isUserProvider ? '' : this.classProduct,
      size: this.pageSize,
      page: this.pageIndex,
    };

    this.loading = true;
    this.integracaoService.getListDivergentData(options).subscribe({
      next: (res: any) => {
        this.list = res.content;
        this.tableData = this.list;
        if (this.tableData && this.tableData.length <= 0) {
          this._snackBar.open(
            'Nenhum registro encontrado para o filtro solicitado',
            undefined,
            {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: 'right',
              verticalPosition: 'top',
            }
          );
        }
        this.length = res.totalElements;

        if (this.providerCompanyList.some((e) => e.checked)) {
          this.tableData = this.tableData.filter(
            (elem: any) =>
              this.providerCompanyList.filter(
                (providerCompany) =>
                  elem?.provider &&
                  elem?.provider.toLowerCase() ===
                    providerCompany.value.toLowerCase() &&
                  providerCompany.checked
              ).length > 0
          );
        }

        this.dataSource = new MatTableDataSource(this.tableData);
        this.getProviderCompanies();

        this.tableActive = true;
        this.loading = false;
      },
      error: (err) => {
        this.list = [];
        this.length = 0;
        this.tableData = this.list;
        this.dataSource = new MatTableDataSource(this.tableData);
        this._snackBar.open(
          'Nenhum registro encontrado para o filtro solicitado',
          undefined,
          {
            duration: 2 * 1000,
            panelClass: ['alert_error'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
          }
        );

        this.loading = false;
      },
    });
  }

  public listUnprocessedData(
    startDate: any,
    finishDate: any,
    providerDocument: any
  ) {
    if (this.classProduct === 'flv') {
      this.classProduct = 'FLV';
    }

    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};

    if (
      this.user.authorities.some((item: string) => item === 'ROLE_PROVIDER')
    ) {
      this.isUserProvider = true;
    } else {
      this.isUserProvider = false;
    }

    const options = {
      startDate: startDate,
      finishDate: finishDate,
      providerDocument: this.isUserProvider ? providerDocument : '',
      productType: this.isUserProvider ? '' : this.classProduct,
      size: this.pageSize,
      page: this.pageIndex,
    };

    this.loading = true;
    this.integracaoService.getListUnprocessedData(options).subscribe({
      next: (res: any) => {
        this.list = res.content;
        this.tableData = this.list;
        if (this.tableData && this.tableData.length <= 0) {
          this._snackBar.open(
            'Nenhum registro encontrado para o filtro solicitado',
            undefined,
            {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: 'right',
              verticalPosition: 'top',
            }
          );
        }
        this.length = res.totalElements;

        if (this.providerCompanyList.some((e) => e.checked)) {
          this.tableData = this.tableData.filter(
            (elem: any) =>
              this.providerCompanyList.filter(
                (providerCompany) =>
                  elem?.provider &&
                  elem?.provider.toLowerCase() ===
                    providerCompany.value.toLowerCase() &&
                  providerCompany.checked
              ).length > 0
          );
        }

        this.dataSource = new MatTableDataSource(this.tableData);
        this.getProviderCompanies();

        this.tableActive = true;
        this.loading = false;
      },
      error: (err) => {
        this.list = [];
        this.length = 0;
        this.tableData = this.list;
        this.dataSource = new MatTableDataSource(this.tableData);
        this._snackBar.open(
          'Nenhum registro encontrado para o filtro solicitado',
          undefined,
          {
            duration: 2 * 1000,
            panelClass: ['alert_error'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
          }
        );

        this.loading = false;
      },
    });
  }

  protected getProviderCompanies() {
    if (this.firstRender) {
      if (!this.isCarrefour) {
        this.tableData.map((item: any) => {
          if (
            item.provider != '' &&
            !this.providerCompanyList.some(
              (provider) => provider.value === item?.provider
            )
          ) {
            this.providerCompanyList.push({
              checked: false,
              value: item?.provider,
              document: item.documentProvider,
            });
          }
        });
      } else {
        this.tableData.map((item: any) => {
          if (
            item.destiny != '' &&
            !this.providerCompanyList.some(
              (provider) => provider.value === item?.destiny
            )
          ) {
            this.providerCompanyList.push({
              checked: false,
              value: item?.destiny,
              document: item.destiny.document,
            });
          }
        });
      }
    }
    this.firstRender = false;
  }

  protected exportToExcel(json: any[]): void {
    const options = {
      productType: !this.isUserProvider ? this.classProduct : '',
      startDate: this.form.get('start')?.value,
      endDate: this.form.get('finish')?.value,
    };

    if (json.length > 0) {
      if (this.isReceipt) {
        this.integracaoService
          .exportReceiptWithoutIntegrationXlsx(options)
          .subscribe({
            next: (res: any) => {
              let response = res;
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  success: true,
                  successText: response,
                },
              });
              this.loading = false;
            },
            error: (err: any) => {
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  error: true,
                  errorText: 'Falha ao gerar arquivo para exportação!',
                },
              });
              this.loading = false;
            },
          });
      }

      if (this.isDate || this.isDivergence) {
        const dataToSend = {
          ...options,
          type: addTypeOfExportXlsx(this.isDate),
        };

        this.integracaoService.exportXlsx(dataToSend).subscribe({
          next: (res: any) => {
            let response = res;
            this.dialog.open(ModalConfirmComponent, {
              data: {
                success: true,
                successText: response,
              },
            });
            this.loading = false;
          },
          error: (err) => {
            this.dialog.open(ModalConfirmComponent, {
              data: {
                error: true,
                errorText: 'Falha ao gerar arquivo para exportação!',
              },
            });
            this.loading = false;
          },
        });
      }
    }

    // if (exportData.length > 0) {
    //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    //   const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    //   if (this.isReceipt) {
    //     this.saveAsExcelFile(excelBuffer, 'Recebimento sem integração');
    //   } else if (this.isDivergence) {
    //     this.saveAsExcelFile(excelBuffer, 'Divergência de dados sem integração');
    //   } else if (this.isDate) {
    //     this.saveAsExcelFile(excelBuffer, 'Dados não processados');
    //   }
    // }
  }

  changeProviderValue(event: any) {
    this.form.get('document')?.setValue(event);
  }

  protected clearFilters(): void {
    this.form.get('document')?.setValue('');
    this.form.get('start')?.setValue('');
    this.form.get('finish')?.setValue('');

    this.list = [];
    this.providerCompanyList = [];
    this.tableData = this.list;
    this.dataSource = new MatTableDataSource(this.tableData);
    this.inputProvider.setValue('');
    if (!this.isCarrefour) {
      this.dataSource.paginator = this.paginator;
    }
    this.tableActive = false;
  }

  protected onClickCheck(checkbox: any, type: string) {
    checkbox[type] = !checkbox[type];
  }

  protected onChanged = (evt: any, index: number, type: string) => {
    this.loading = true;

    if (type === 'provider') {
      this.providerCompanyList[index].checked = evt.target.checked;
    }

    this.submitForm();
  };

  maskCpfCnpj(value: string): string {
    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(
        9
      )}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(
        0,
        2
      )}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

  public formatCpfCnpj(item: string) {
    return formatCpfCnpj(item);
  }

  protected checkStartCompletionDate() {
    const completionDate: any = this.form.get('start')?.value;
    const minDate = new Date(completionDate);
    this.minCompletionDate = minDate.setDate(minDate.getDate() + 1);

    this.form.get('finish')?.setValue('');
  }
}
