import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SelectInputComponent } from '../select-input/select-input.component';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-provider-select-filter',
  templateUrl: './provider-select-filter.component.html',
  styleUrls: ['./provider-select-filter.component.scss']
})
export class ProviderSelectFilterComponent {
  @Input() requestEnterprise: boolean = false;
  @Input() isUserProvider: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output() handleChangeProviderValue  = new EventEmitter();
  @ViewChild('inputProvider') inputProvider!: SelectInputComponent;
  providerOptionsList: Array<{ name: string, value: string }> = [];
  loading: boolean = false;
  providersList: any[] = [];

  constructor(
    private fornecedoresService: FornecedoresService,
    private companyService: CompanyService,
  ) { }

  changeProviderValue(event:any ){
    this.handleChangeProviderValue.emit(event);
  }

  loadMoreProviderOptionsList(event: any) {
    this.loading = true;
    this.inputProvider.loadMoreButton = true;
    this.fornecedoresService.getProviderOutsourcedByName(this.inputProvider.value, 10, this.inputProvider.page).subscribe((res: any) => {
      if (this.inputProvider.page === 0) {
        this.providersList = res.content;
      } else {
        res.content.map((item: any) => {
          if (!this.providersList.some((element: any) => element === item)) {
            this.providersList.push(item);
          }
        });
      }
      if (res.totalItems === this.providersList.length) {
        this.inputProvider.loadMoreButton = false;
      }
      this.inputProvider.page++;
      this.getProviderList();
      this.inputProvider.changeOptionsFilter(this.providerOptionsList);
      this.inputProvider.optionsShow = true;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  setValue(value: string) {
    this.inputProvider.value = value;
    this.inputProvider.change = false;
  }

  public getProviderList():void {
    this.loading = true;
    this.providerOptionsList = [];
    if (this.providersList.length > 0) {
      this.providerOptionsList = this.providersList.map((item: any) => {
        let option = {
          name: `${item.corporateName} - ${formatCpfCnpj(item.document)}`, value: item.document
        }
        return option;
      });
      this.inputProvider.changeOptionsFilter(this.providerOptionsList);
    }
    this.loading = false;
  }


  getProviderSearchOptions(event: any) {
    if (event.length > 0) {
      this.loading = true;
      this.fornecedoresService.getProviderOutsourcedByName(event, 10, this.inputProvider.page).subscribe((res: any) => {
        this.providersList = res.content;
        this.getProviderList();
        this.inputProvider.changeOptionsFilter(this.providerOptionsList);
        this.inputProvider.optionsShow = true;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    }
  }

  handleChanged() {
    this.inputProvider.change = true;
  }

  cleanFilter() {
    this.inputProvider.value = '';
  }

  loadMoreEnterprisesOptionsList(event: any): void {
    this.loading = true;
    this.inputProvider.loadMoreButton = true;
    this.companyService.getEnterpriseProjectionByName(this.inputProvider.value, 10, this.inputProvider.page).subscribe((res: any) => {
      if (this.inputProvider.page === 0) {
        this.providersList = res.content;
      } else {
        res.content.map((item: any) => {
          if (!this.providersList.some((element: any) => element === item)) {
            this.providersList.push(item);
          }
        });
      }
      if (res.totalItems === this.providersList.length) {
        this.inputProvider.loadMoreButton = false;
      }
      this.inputProvider.page++;
      this.getProviderList();
      this.inputProvider.changeOptionsFilter(this.providerOptionsList);
      this.inputProvider.optionsShow = true;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  getEnterpriseSearchOptions(event: any): void {
    if (event.length > 0) {
      this.loading = true;
      this.companyService.getEnterpriseProjectionByName(event, 10, this.inputProvider.page).subscribe((res: any) => {
        this.providersList = res.content;
        this.getProviderList();
        this.inputProvider.changeOptionsFilter(this.providerOptionsList);
        this.inputProvider.optionsShow = true;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    }
  }
}
