import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  buildExportXlsxParams,
  isUserProvider,
} from '../utils/see-integration-utils/integration-utils';
import { ExportXlsxOptions } from '../pages/integracao/interfaces/ExportXlsxOptions';

interface Integracao {
  integration: String;
  provider: String;
  documentProvider: String;
  productName: String;
  invoiceNumber: number;
  identifier: number;
  movementDate: String;
  completionDate: String;
  requestedQuantity: number;
  amount: number;
  receivedQuantity: number;
  returnedQuantity: number;
  error: String;
  type: String;
}

@Injectable({
  providedIn: 'root',
})
export class IntegracaoService {
  private token: any;
  private item: any;
  private httpOtions: any;
  storage: Storage;

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};

    this.httpOtions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
  }

  public getListReceiptWithoutIntegration(options: {
    startDate: string;
    finishDate: string;
    providerDocument?: string;
    productType?: string;
    size: number;
    page: number;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = {};

    params = new HttpParams()
      .set('startDate', options.startDate)
      .set('endDate', options.finishDate)
      .set('size', options.size)
      .set('page', options.page)
      .set('productType', options.productType!);

    if (options.providerDocument) {
      params = new HttpParams()
        .set('providerDocument', options.providerDocument)
        .set('startDate', options.startDate)
        .set('endDate', options.finishDate)
        .set('size', options.size)
        .set('page', options.page);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(`${environment.api}/errors-receivement/find`, http)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          throw error;
        })
      );
  }

  public getListDivergentData(options: {
    startDate: string;
    finishDate: string;
    providerDocument?: string;
    productType?: string;
    size: number;
    page: number;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    let params = new HttpParams()
      .set('startDate', options.startDate)
      .set('endDate', options.finishDate)
      .set('type', 'DIVERGENCIA_DE_DADOS')
      .set('size', options.size)
      .set('page', options.page);

    if (options.providerDocument) {
      params = params.set('providerDocument', options.providerDocument);
    }
    if (options.productType) {
      params = params.set('productType', options.productType);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/errors-entry-flv/find`, http).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public getListUnprocessedData(options: {
    startDate: string;
    finishDate: string;
    providerDocument?: string;
    productType?: string;
    page: number;
    size: number;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('startDate', options.startDate)
      .set('endDate', options.finishDate)
      .set('type', 'DADOS_NAO_PROCESSADOS')
      .set('size', options.size)
      .set('page', options.page);

    if (options.providerDocument) {
      params = params.set('providerDocument', options.providerDocument);
    }
    if (options.productType) {
      params = params.set('productType', options.productType);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/errors-entry-flv/find`, http).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public getCarrefourData(filters: {
    startDate: any;
    endDate: any;
    page: number;
    size: number;
    status: string;
    providerDocument?: string;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    let params = new HttpParams()
      .set('page', filters.page)
      .set('size', filters.size)
      .set('status', filters.status);

    if (filters.providerDocument) {
      params = params.set('providerDocument', filters.providerDocument);
    }

    if (filters.startDate) {
      params = params.set('startDate', filters.startDate);
    }

    if (filters.endDate) {
      params = params.set('endDate', filters.endDate);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(
        `${environment.api}/movement-service/getReceivements/carrefour`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public exportReceiptWithoutIntegrationXlsx(options: ExportXlsxOptions) {
    const userItem = this.storage.getItem('user');

    let params = buildExportXlsxParams(options);

    if (!isUserProvider(userItem)) {
      params = params.set(
        'productType',
        options.productType ? options.productType : 'FLV'
      );
    }

    const httpOptionsWithParams = {
      ...this.httpOtions,
      params: params,
      responseType: 'string',
    };

    return this.http
      .get<string>(
        `${environment.api}/xlsx/errors/receivement/find`,
        httpOptionsWithParams
      )
      .pipe(
        map((response: HttpEvent<string>) => {
          return response;
        }),
        catchError((error: Error) => {
          throw error;
        }),
        take(1)
      );
  }

  public exportXlsx(options: ExportXlsxOptions) {
    const userItem = this.storage.getItem('user');

    let params = buildExportXlsxParams(options);

    if (!isUserProvider(userItem)) {
      params = params.set(
        'productType',
        options.productType ? options.productType : 'FLV'
      );
    }

    const httpOptionsWithParams = {
      ...this.httpOtions,
      params: params,
      responseType: 'string',
    };

    return this.http
      .get<string>(
        `${environment.api}/xlsx/errors/entry-flv/find`,
        httpOptionsWithParams
      )
      .pipe(
        map((response: HttpEvent<string>) => {
          return response;
        }),
        catchError((error: Error) => {
          throw error;
        }),
        take(1)
      );
  }
}
