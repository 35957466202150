<div class="solicitacoes-respondidas-content">
  <div class="header">
    <h2>Solicitações Respondidas</h2>
  </div>
    <form [formGroup]="form" class="form-area">

      <div class="form-group" tabindex="-1" style="min-width: 250px;">
        <input type="date" class="form-input" id="initialDate" name="initialDate" formControlName="initialDate" />
        <label class="form-label" for="initialDate" [ngClass]="{ focus: form.get('initialDate')?.value }">
          Período Data inicial
        </label>
        <span class="alert"
          *ngIf="form.get('initialDate')?.errors && (form.get('initialDate')?.touched || form.get('initialDate')?.dirty)">
          Campo obrigatório
        </span>
      </div>
  
      <div class="form-group" tabindex="-1" style="min-width: 250px;">
        <input type="date" class="form-input" id="finalDate" name="finalDate" formControlName="finalDate" />
        <label class="form-label" for="finalDate" [ngClass]="{ focus: form.get('finalDate')?.value }">
          Período Data final
        </label>
        <span class="alert"
          *ngIf="form.get('finalDate')?.errors && (form.get('finalDate')?.touched || form.get('finalDate')?.dirty)">
          Campo obrigatório
        </span>
      </div>
  
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="producer" name="producer" formControlName="producer" />
        <label class="form-label" for="producer" [ngClass]="{ focus: form.get('producer')?.value }">
          Produtor
        </label>
        <span class="alert"
          *ngIf="form.get('producer')?.errors && (form.get('producer')?.touched || form.get('producer')?.dirty)">
          Campo obrigatório
        </span>
      </div>
  
      <div class="form-select" tabindex="-1">
        <select name="protocol" formControlName="status">
          <option value="" selected style="color: rgba(0, 29, 69, 1); background-color: #f2f2f2;"> Status </option>
          <option value="ENABLED"> Habilitado </option>
          <option value="POST_ANALYSIS_ENABLED"> Habilitado AD </option>
          <option value="NOT_ENABLED"> Em Análise AD </option>
          <option value="POST_ANALYSIS_NOT_ENABLED"> Não Habilitado AD </option>
          <option value="ANALYSIS_IN_PROGRESS"> Analisando </option>
          <option value="VALIDATING"> Validando </option>
          <option value="PENDING"> Pendente </option>
        </select>
        <span class="alert"
          *ngIf="form.get('status')?.errors && (form.get('status')?.touched || form.get('status')?.dirty)">
          Campo obrigatório
        </span>
      </div>
  
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="requestNumber" name="requestNumber" formControlName="requestNumber" />
        <label class="form-label" for="requestNumber" [ngClass]="{ focus: form.get('requestNumber')?.value }">
          Número da Requisição
        </label>
        <span class="alert"
          *ngIf="form.get('requestNumber')?.errors && (form.get('requestNumber')?.touched || form.get('requestNumber')?.dirty)">
          Campo obrigatório
        </span>
      </div>
  
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="car" name="car" formControlName="car" />
        <label class="form-label" for="car" [ngClass]="{ focus: form.get('car')?.value }">
          CAR
        </label>
        <span class="alert" *ngIf="form.get('car')?.errors && (form.get('car')?.touched || form.get('car')?.dirty)">
          Campo obrigatório
        </span>
      </div>
      <!-- <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="status" name="status" formControlName="status" />
        <label class="form-label" for="status" [ngClass]="{ focus: form.get('status')?.value }">
          Status
        </label>
        <span class="alert"
          *ngIf="form.get('status')?.errors && (form.get('status')?.touched || form.get('status')?.dirty)">
          Campo obrigatório
        </span>
      </div> -->
      <button class="button" (click)="getRequestAnsweredList()">
        Consultar
      </button>
      <button class="button" (click)="exportarSolicitacoesRespondidas()" *ngIf="dataSource.filteredData.length > 0">
        Exportar
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.83375 14.6699C1.32947 14.6699 0.897622 14.4902 0.538207 14.1308C0.178792 13.7714 -0.000609693 13.3399 1.55666e-06 12.8362V10.0856H1.83375V12.8362H12.8363V10.0856H14.67V12.8362C14.67 13.3405 14.4903 13.7723 14.1309 14.1317C13.7715 14.4911 13.3399 14.6705 12.8363 14.6699H1.83375ZM7.335 11.0024L2.75063 6.41806L4.03425 5.08859L6.41813 7.47247V-6.10352e-05H8.25188V7.47247L10.6358 5.08859L11.9194 6.41806L7.335 11.0024Z"
            fill="#FFFFFF" />
        </svg>
      </button>
    </form>

  <div class="table-area">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="requestNumber">
        <th mat-header-cell *matHeaderCellDef> Nº da Solicitação </th>
        <td mat-cell *matCellDef="let element"> {{ element.requestNumber }} </td>
      </ng-container>
      <ng-container matColumnDef="requestDate">
        <th mat-header-cell *matHeaderCellDef> Data da Solicitação </th>
        <td mat-cell *matCellDef="let element"> {{ element.requestDate | date:'dd/MM/yyyy' }} </td>
      </ng-container>
      <ng-container matColumnDef="producerName">
        <th mat-header-cell *matHeaderCellDef> Nome Produtor </th>
        <td mat-cell *matCellDef="let element"> {{ element.producerName }} </td>
      </ng-container>
      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef> Documento </th>
        <td mat-cell *matCellDef="let element"> {{ maskCpfCnpj(element.document) }}</td>
      </ng-container>
      <ng-container matColumnDef="propertyName">
        <th mat-header-cell *matHeaderCellDef> Nome do Imóvel </th>
        <td mat-cell *matCellDef="let element"> {{ element.propertyName }}</td>
      </ng-container>
      <ng-container matColumnDef="car">
        <th mat-header-cell *matHeaderCellDef> CAR </th>
        <td mat-cell *matCellDef="let element" style="max-width: 100px; word-wrap: break-word;"> {{ element.car }}</td>
      </ng-container>
      <ng-container matColumnDef="sendDate">
        <th mat-header-cell *matHeaderCellDef> Data de Envio </th>
        <td mat-cell *matCellDef="let element"> {{ element.sendDate | date:'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" [ngClass]="getStatusColor(element.status)">
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="getStatusColor(element.status) === 'green'">
            <path d="M5.225 11.5114L0 6.28644L1.30625 4.98019L5.225 8.89894L13.6354 0.488525L14.9417 1.79478L5.225 11.5114Z" fill="#00D68F"/>
          </svg>
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="getStatusColor(element.status) === 'red'">
            <path d="M14.1385 12.6801C14.3322 12.8739 14.4411 13.1366 14.4411 13.4106C14.4411 13.6846 14.3322 13.9474 14.1385 14.1411C13.9448 14.3348 13.682 14.4437 13.408 14.4437C13.1341 14.4437 12.8713 14.3348 12.6776 14.1411L7.2214 8.6832L1.76351 14.1394C1.56977 14.3331 1.30702 14.4419 1.03304 14.4419C0.75906 14.4419 0.496303 14.3331 0.30257 14.1394C0.108838 13.9456 2.88684e-09 13.6829 0 13.4089C-2.88684e-09 13.1349 0.108838 12.8722 0.30257 12.6784L5.76046 7.22226L0.304289 1.76437C0.110556 1.57063 0.00171879 1.30788 0.00171879 1.0339C0.00171879 0.759919 0.110556 0.497162 0.304289 0.303429C0.498021 0.109697 0.760778 0.000859234 1.03476 0.000859231C1.30874 0.000859228 1.57149 0.109697 1.76523 0.303429L7.2214 5.76132L12.6793 0.30257C12.873 0.108838 13.1358 -4.56449e-09 13.4098 0C13.6837 4.56449e-09 13.9465 0.108838 14.1402 0.30257C14.334 0.496302 14.4428 0.75906 14.4428 1.03304C14.4428 1.30702 14.334 1.56978 14.1402 1.76351L8.68234 7.22226L14.1385 12.6801Z" fill="#F43841"/>
          </svg>
          <span>
            {{ getStatusName(element.status )}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ações </th>
        <td mat-cell *matCellDef="let element">
          <button type="button" class="actions-button" (click)="downloadLaudo(element)" *ngIf="element.status !== 'NOT_ENABLED'">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.83375 14.6699C1.32947 14.6699 0.897622 14.4902 0.538207 14.1308C0.178792 13.7714 -0.000609693 13.3399 1.55666e-06 12.8362V10.0856H1.83375V12.8362H12.8363V10.0856H14.67V12.8362C14.67 13.3405 14.4903 13.7723 14.1309 14.1317C13.7715 14.4911 13.3399 14.6705 12.8363 14.6699H1.83375ZM7.335 11.0024L2.75063 6.41806L4.03425 5.08859L6.41813 7.47247V-6.10352e-05H8.25188V7.47247L10.6358 5.08859L11.9194 6.41806L7.335 11.0024Z" fill="#99CA3C"/>
            </svg>
          </button>
          <button type="button" class="actions-button" (click)="viewRequest(element)">
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.125 6.875C13.125 7.7038 12.7958 8.49866 12.2097 9.08471C11.6237 9.67076 10.8288 10 10 10C9.1712 10 8.37634 9.67076 7.79029 9.08471C7.20424 8.49866 6.875 7.7038 6.875 6.875C6.875 6.0462 7.20424 5.25134 7.79029 4.66529C8.37634 4.07924 9.1712 3.75 10 3.75C10.8288 3.75 11.6237 4.07924 12.2097 4.66529C12.7958 5.25134 13.125 6.0462 13.125 6.875Z" fill="#99CA3C"/>
              <path d="M0 6.875C0 6.875 3.75 0 10 0C16.25 0 20 6.875 20 6.875C20 6.875 16.25 13.75 10 13.75C3.75 13.75 0 6.875 0 6.875ZM10 11.25C11.1603 11.25 12.2731 10.7891 13.0936 9.96859C13.9141 9.14812 14.375 8.03532 14.375 6.875C14.375 5.71468 13.9141 4.60188 13.0936 3.78141C12.2731 2.96094 11.1603 2.5 10 2.5C8.83968 2.5 7.72688 2.96094 6.90641 3.78141C6.08594 4.60188 5.625 5.71468 5.625 6.875C5.625 8.03532 6.08594 9.14812 6.90641 9.96859C7.72688 10.7891 8.83968 11.25 10 11.25Z" fill="#99CA3C"/>
            </svg>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">Nenhum registro encontrado</td>
      </tr>
    </table>
  </div>
  <div class="paginacao">
    <mat-paginator id="paginator" [pageSize]="size" [pageIndex]="page" [pageSizeOptions]="[5, 10, 25]" [length]="length" (page)="handlePaginate($event)" showFirstLastButtons> </mat-paginator>
  </div>
  <div class="text-field">
    <span> Legenda </span>
    <p><strong>Habilitado:</strong> Sem nenhum problema na análise automatizada. </p>
    <p><strong>Habilitado AD:</strong> Apresentou algum problema durante a análise automatizada, e após uma análise de um especialista, foi habilitado.</p>
    <p><strong class="not">Não Habilitado AD:</strong> Apresentou algum problema durante a análise automatizada, sendo necessário uma análise de um especialista, havendo inconsistências.</p>
    <p><strong class="not" style="color: #ffd900;">Em Análise AD:</strong> Apresentou algum problema durante a análise automatizada, sendo necessário uma análise de um especialista, verificando possíveis inconsistências.</p>
  </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>
